<template>
  <AppHeader />
  <br>
    <Suspense>
      <template #default>
        <Contents :id="id" />
      </template>
      <template #fallback>
        <Spinner />
      </template>
    </Suspense>
  <AppFooter />
</template>

<script>
import AppHeader from "@/components/Include/AppHeader.vue";
import AppFooter from "@/components/Include/AppFooter.vue";
import Spinner from "@/components/Spinners/Spinner.vue";
import { defineAsyncComponent, Suspense } from "vue";
const Contents = defineAsyncComponent(() =>
  import('@/components/Pages/Contents.vue')
)
export default {
  props: {
    id: String
  },
  name: "Content",
  components: {
    AppHeader,
    AppFooter,
    Contents,
    Suspense,
    Spinner
  },
};
</script>

<style>
</style>